var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c("fb-button", {
            attrs: {
              slot: "right",
              icon: "menu",
              size: "small",
              type: "primary",
            },
            slot: "right",
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "history-case-verdict" },
        _vm._l(_vm.verdicts, function (verdict, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "verdict-item",
              on: {
                click: function ($event) {
                  return _vm.showVerdictContent(verdict)
                },
              },
            },
            [
              _c("span", { staticClass: "verdict-proceeding" }, [
                _vm._v(_vm._s(verdict.court_proceeding)),
              ]),
              _c("p", { staticClass: "verdict-title" }, [
                _vm._v(_vm._s(verdict.case_name)),
              ]),
              _c("ul", { staticClass: "verdict-info" }, [
                _c("li", { staticClass: "verdict-key-value" }, [
                  _c("label", { staticClass: "verdict-key" }, [
                    _vm._v("裁判日期："),
                  ]),
                  _c("label", { staticClass: "verdict-value" }, [
                    _vm._v(_vm._s(verdict.trial_date)),
                  ]),
                ]),
                _c("li", { staticClass: "verdict-key-value" }, [
                  _c("label", { staticClass: "verdict-key" }, [
                    _vm._v("案由："),
                  ]),
                  _c("label", { staticClass: "verdict-value" }, [
                    _vm._v(_vm._s(verdict.name)),
                  ]),
                ]),
                _c("li", { staticClass: "verdict-key-value" }, [
                  _c("label", { staticClass: "verdict-key" }, [
                    _vm._v("代理人："),
                  ]),
                  _c("label", { staticClass: "verdict-value" }, [
                    _vm._v(_vm._s(verdict.court_name)),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }