<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <div class="history-case-verdict">
      <div class="verdict-item"
           v-for="(verdict, index) in verdicts"
           :key="index"
           @click="showVerdictContent(verdict)">
        <span class="verdict-proceeding">{{ verdict.court_proceeding }}</span>
        <p class="verdict-title">{{ verdict.case_name }}</p>
        <ul class="verdict-info">
          <li class="verdict-key-value">
            <label class="verdict-key">裁判日期：</label>
            <label class="verdict-value">{{ verdict.trial_date }}</label>
          </li>
          <li class="verdict-key-value">
            <label class="verdict-key">案由：</label>
            <!-- todo 案由对应的key不正确，需修正 -->
            <label class="verdict-value">{{ verdict.name }}</label>
          </li>
          <li class="verdict-key-value">
            <label class="verdict-key">代理人：</label>
            <!-- todo 代理人对应的key不正确，前面需要一个空字符串，需修正 -->
            <label class="verdict-value">{{ verdict.court_name }}</label>
          </li>
        </ul>
      </div>
    </div>
  </fb-page>
</template>

<script>
export default {
  name: 'history-case-verdict',
  data () {
    return {
      title: '',
      verdictKeys: ['裁判日期', '案由', '代理人'],
      verdicts: [],
      proxyPerson: '  '
    }
  },
  created () {
    this.title = this.$route.params.litigant
    console.log('title', this.title)

    this.summary = this.$route.params.summary

    // todo 接口需要的入参尚不明确
    this.$axios
      .get('/api/company/summary/verdict.json', {
        params: {
          name: this.title, // 当事人名称
          summary: this.summary // 案由
        }
      })
      .then(res => {
        this.verdicts = res.data.result
      })
  },
  methods: {
    showVerdictContent (verdict) {
      console.log('verdict', verdict)
      this.$router.push({
        name: 'casedetail-lsal-content',
        params: {
          docId: verdict.doc_id
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.history-case-verdict
  text-align center
  .verdict-item
    width 90%
    margin 15px auto
    border-radius 5px
    background white
    text-align left
    padding 10px
    position relative
    .verdict-proceeding
      position absolute
      top -6px
      right 30px
      text-align center
      display inline-block
      width 50px
      height 14px
      line-height 14px
      border-radius 2px
      background #3EB3F0
    .verdict-title
      font-size 15px
      margin-bottom 12px
    .verdict-info
      font-size 14px
      .verdict-key-value
        margin-top 12px
        .verdict-key
          color #169BD5
</style>
